import React from 'react'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'

const NotFoundPage = () => (
  <Layout>
    <SeoHead
      title="404 Not Found"
      description="This is the 404 not found page"
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
